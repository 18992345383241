import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure( {
  showSpinner : false
} )

// import http from '@/utils/request'
import cookies from '@/utils/cookie'
import CONFIGS from '../config'
const whiteList = [
  '/',
  '/industryCase',
  '/industryCase/details',
  '/networkAccess',
  '/community',
  '/community/answerDetail',
  '/community/articleDetail',
  '/community/communityAsk',
  '/templateLibrary',
  '/templateForm',
  '/homeCodeMain',
  '/releaseLog',
  '/price',
  '/search',
  '/aboutUs',
  '/map',
  '/scanMap',
  '/yidong',
  '/snmsIframe',
  '/maEmote',
  '/business',
  '/company',
  '/createCode',
  '/pageTemplate',
  '/identityQuery',
  '/identityQuery/result',
  '/aiAgent'
]

const isClient = process.browser

export default ( {
  app,
  store
} ) => {
  app.router.beforeEach( async( to, from, next ) => {
    isClient && NProgress.start()
    if ( isClient ) {
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
      // safari
      window.pageYOffset = 0

      const hasToken = cookies.get( CONFIGS.COOKIE_PREFIX + 'token' )
      if ( hasToken ) {
        if ( to.path === '/login' ) {
          next( {
            path : '/'
          } )
          NProgress.done()
        } else {
          const hasRoles = store.getters.roles && store.getters.roles.length > 0
          if ( hasRoles ) {
            next()
          } else {
            try {
              // const params = {
              //   token : hasToken
              // }
              // const { roles } = await store.dispatch( 'user/getUserInfo', tokenData )
              // next( { ...to, replace : true } )
            } catch ( error ) {
              // await store.dispatch( 'user/loginOut' )
              // next( `/login` )
              // NProgress.done()
            }
          }
        }
      } else {
        if ( whiteList.indexOf( to.path ) !== -1 ) {
          next()
        } else {
          next( `/login` )
          NProgress.done()
        }
      }
    }
    next()
    isClient && NProgress.done()
  } )

  app.router.afterEach( () => {
    isClient && NProgress.done()
  } )
}
